/**
 * Open / Close hamburger menu.
 *
 * @returns {{init: init}}
 */
const NavCLick = (() => {
  const DOM = {};

  let isMobile = window.matchMedia("only screen and (max-width: 990px)").matches;

  const cacheDOM = () => {
    DOM.hamburger = document.getElementsByClassName("navClick");
    DOM.menu = document.getElementsByClassName("nav");
    DOM.submenu = document.getElementsByClassName("menu-item-has-children");
  };

  const openMenu = () => {
    let button = DOM.hamburger[0];
    let container = DOM.menu[0];

    if (isMobile) {
      if (!button.classList.contains('active')) {
        button.classList.add('active');
        container.classList.add('active');

        container.style.height = 'auto';
        let height = container.clientHeight + "px";

        container.style.height = '0px';

        setTimeout(() => {
          container.style.height = height;
        }, 0);

      } else {
        container.style.height = '0px';
        button.classList.remove('active');

        container.addEventListener('transitionend',  () => {
          container.classList.remove('active');
        }, {
          once: true
        });
      }
    }
  };

  const subMenu = (e) => {
    const parent = e.target.offsetParent;
    const nav = e.target.offsetParent.parentElement.parentElement.parentElement;

    if (!parent.classList.contains('active')) {
      e.preventDefault();

      parent.classList.add('active');

      let mainHeight = parent.parentElement.offsetHeight;
      nav.style.height = mainHeight + "px";
    }
  };

  const eventListeners = () => {
    DOM.hamburger[0].addEventListener('click', () => {
      openMenu();
    });

    if (isMobile) {
      for (let i = 0; i < DOM.submenu.length; i += 1) {
        DOM.submenu[i].addEventListener("click", subMenu);
      }
    }
  };

  const init = () => {
    cacheDOM();
    eventListeners();
  };

  return {
    init
  };
})();

export default NavCLick;
