/**
 * Open / Close hamburger menu.
 *
 * @returns {{init: init}}
 */
const ScrollTop = (() => {
  const DOM = {};
  let intervalId = 0;

  const cacheDOM = () => {
    DOM.buttonTop = document.getElementsByClassName("scroll-top");

  };

  const showButton = () => {
    if (window.pageYOffset < 200) {
      DOM.buttonTop[0].style.display = 'none';
    } else {
      DOM.buttonTop[0].style.display = 'block';

    }
  };

  const scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  };

  const scrollTop = () => {
    intervalId = setInterval(scrollStep, 9.66);
  };

  const eventListeners = () => {
    DOM.buttonTop[0].addEventListener('click', () => {
      scrollTop();
    });

    window.addEventListener('scroll', () => {
      showButton();
    })
  };

  const init = () => {
    cacheDOM();
    showButton();
    eventListeners();
  };

  return {
    init
  };
})();

export default ScrollTop;
