// NPM Imports
import 'intersection-observer';
// Helpers

// Internal imports
import Animations from "./Animations";
import NavClick from "./NavClick";
import Carousel from "./Carousel";
import Accordion from "./Accordion";
import ScrollTop from "./ScrollTop";
import LazyImage from "./LazyImage";
import "./Iframe";


/**
 * Main App module used in index.js to initialise all other modules, includes helper
 * functions/modules for use in initialising modules depending on various criteria
 *
 * @returns {{init: init}}
 */

const App = (() => {
  const loadedModules = {};

  /**
   * @param Module {Object}
   * @param Module.init {Function}
   * @param name {string|boolean}
   * @param options {Object}
   */
  const alwaysInit = (Module, name = false, options = false) => {
    if (name) {
      loadedModules[name] = Module;
    }
    Module.init(options);
  };

  /**
   * @param Module {Object}
   * @param Module.init {Function}
   * @param name {string|boolean}
   * @param CB {Function|Boolean}
   * @param options {Object}
   */
  const initialiseOnHomepage = (
    Module,
    name = false,
    CB = false,
    options = false
  ) => {
    if (window.isHomePage) {
      Module.init(options);
      if (name) {
        loadedModules[name] = Module;
      }
      if (CB !== false) CB(true);
    } else if (CB !== false) {
      CB(false);
    }
  };

  /**
   * @param Module {Object}
   * @param Module.init {Function}
   * @param name {string|boolean}
   * @param options {Object}
   *
   * Detects preferred reduce motion before initiating the module
   */
  const initialiseIfAnimationAllowed = (
    Module,
    name = false,
    options = false
  ) => {
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      Module.init(options);
      if (name) {
        loadedModules[name] = Module;
      }
    }
  };


  const init = () => {
    // Always init
    //alwaysInit();

    alwaysInit(NavClick, "NavClick");
    alwaysInit(Carousel, "Carousel", [
      {
        track: 'videos__slider--track',
        next: 'videos__next',
        prev: 'videos__prev',
        dots: 'videos__indicator',
        nav: 'videos__nav'
      },
      {
        track: 'hero-image__slider--track',
        next: 'hero-image__next',
        prev: 'hero-image__prev',
        dots: 'hero-image__slider--dot',
        nav: 'hero-image__slider--dots'
      },
      {
        track: 'testimonial__slider--track',
        next: 'testimonial__next',
        prev: 'testimonial__prev',
        dots: 'testimonial__slider--dot',
        nav: 'testimonial__slider--dots'
      }
      ]);
    alwaysInit(Accordion, "Accordion");
    alwaysInit(ScrollTop, "ScrollTop");
    alwaysInit(LazyImage, "LazyImage");

    // Homepage
    //initialiseOnHomepage();


    // Reduced motion
    initialiseIfAnimationAllowed(Animations, "Animations");

  };

  return {
    init
  };
})();

export default App;
